<template>
  <div class="detail">
    <div class="listBanner">
      <img class="img_c1" src="../assets/img/banner_img5.jpg" alt="">
    </div>
    
    <div class="detailBox wrap">
      <el-row :gutter="15">
        <el-col :span="7">
          <div class="detailLeft">
            <div class="detailLeftWrap">
              <div class="detailLeftHd">
                <span class="detailLeftHdIco"><i class="el-icon-arrow-right"></i></span>
                <span class="detailLeftHdTit">公司要闻</span>
              </div>
              <div class="detailLeftCont">
                <ul class="detailLeftList">
                  <li class="single-line" v-for="(item, index) in newsList01" :key="index">
                    <a v-if="item.is_link === 1" :href="item.link_url" target="_blank">{{item.title}}</a>
                    <a v-else :href="`${urlName}detail?id=${item.id}`">{{item.title}}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="detailLeftWrap">
              <div class="detailLeftHd">
                图片新闻
              </div>
              <div class="detailLeftCont">
                <ul class="detailLeftImgListImg">
                  <li class="" v-for="(item, index) in newsList02" :key="index">
                    <a v-if="item.is_link === 1" class="clearfloat" :href="item.link_url" target="_blank">
                      <div class="fl detailLeftListImgPic">
                        <el-image
                          style="width: 100%; height: 100%"
                          :src="item.img"
                          fit="cover"></el-image>
                      </div>
                      <div class="detailLeftListImgInner">
                        {{item.title}}
                      </div>
                    </a>
                    <a v-else class="clearfloat" :href="`${urlName}detail?id=${item.id}`">
                      <div class="fl detailLeftListImgPic">
                        <el-image
                          style="width: 100%; height: 100%"
                          :src="item.img"
                          fit="cover"></el-image>
                      </div>
                      <div class="detailLeftListImgInner">
                        {{item.title}}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="17">
          <div class="detailRight">
            <div class="detailRightTop clearfloat">
              <div class="fl detailRightTopPosition">
                <span>当前位置：</span>
              </div>
              <div class="fl">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i>首页</el-breadcrumb-item>
                  <el-breadcrumb-item :to="{ path: '/list' }">新闻资讯</el-breadcrumb-item>
                  <el-breadcrumb-item :to="{ path: '/list' }">公司要闻</el-breadcrumb-item>
                  <el-breadcrumb-item>新闻详情</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
            </div>
            <div class="detailRightWrap">
              <div class="detailHd">
                <div class="detailTitle">{{infor.title}}</div>
                <div class="detailTitleB">
                  <span>发布时间：{{dayjs(infor.create_time).format('YYYY-MM-DD')}}</span>
                  <span>浏览次数：{{infor.view_number}}</span>
                </div>
                <!-- <div class="detailTitleLine"></div> -->
              </div>
              <div class="detailCont" v-html="infor.content">

              </div>
            </div>
            
          </div>
        </el-col>
      </el-row>
      
    </div>
  </div>
</template>
<script>
export default {
  name: 'detail',
  data(){
    return {
      newsList01: [],
      newsList02: [],
      infor: {
        title: '',
        update_time: '',
        view_number: 0,
        content: ''
      }
    }
  },
  mounted(){
    var _this = this
    if(_this.$route.query.id){
      _this.listId = _this.$route.query.id
    }
    _this.getDetail(_this.listId)
    _this.getList01()
    _this.getList02()
  },
  methods: {
    // 查详情
    async getDetail(id) {
      var _this = this;

      var { data } = await _this.$http.post( _this.apiUrl + "/news/getNewsDetail", { id: id });
      _this.infor = data.data;
    },
    // 公司要闻
    async getList01() {
      var _this = this;
      
      _this.loading = true
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: 442, page: 1, pageSize: 10});
      // console.log('data :>> ', data);
      _this.newsList01 = data.data.list.slice(0, 5)
      _this.total = data.data.total
      _this.loading = false
    },
    // 新闻图片
    async getList02() {
      var _this = this;
      
      _this.loading = true
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsListImg', {group_id: 442, page: 1, pageSize: 20});
      // console.log('data :>> ', data);
      _this.newsList02 = data.data.list.slice(0, 5)
      _this.total = data.data.total
      _this.loading = false
    },
  }
}
</script>